export const flipWinningType = 1;
export const flipAnimation = "horizontal";
export const logoReverseColour = "red";
export const cardReverseColour = "#023487";
export const losingIcon = require("../assets/img/redClover.png");
export const defaultWinningIcon = require("../assets/img/icoWin.png");
export const flipItem = [
  {
    type: "square",
    frontImg: require("../assets/img/redClover.png"),
    backImg: require("../assets/img/ico2.png"),
    text: "lose",
  },
  {
    type: "square",
    frontImg: require("../assets/img/redClover.png"),
    backImg: require("../assets/img/ico2.png"),
    text: "lose",
  },
  {
    type: "square",
    frontImg: require("../assets/img/logo.png"),
    backImg: require("../assets/img/ico2.png"),
  },
];
